






import { Component, Vue } from "vue-property-decorator";
@Component
export default class BeforeArrivalInfo extends Vue {
  pageInfo = {};

  PageData() {
    return this.$route.params.subPage;
  }
  created() {
    this.pageInfo = this.PageData();
  }
}
